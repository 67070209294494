@import url(https://fonts.googleapis.com/css?family=Fira+Sans:400,500,600,700,800);
h1,h2,h3,h4,h5,h6,label,span {
  font-weight: 500;
  font-family: 'Fira Sans', sans-serif;
}

html, body, #root {
  height: 100%;
  width: 100%;
}

body {
  display: flex;
  font-weight: 400;
  font-family: 'Fira Sans', sans-serif;
}

frexcoColor {
  background-color: #455A64;
  background-color: #AED581;
}

.btn-primary{
    color:#fff;
    background-color:#88BC23;
    border: None;
}

